import {Response5xx, Response400, Response401, Response403, Response404, Response504, ResponseUnknownStatus} from "./response_error";

export const recognizeResponseErrors =
    (url: string) =>
    async (res: Response): Promise<Response> => {
        const status = res.status;

        if (status === 400) {
            const res400Err = new Response400(res, url);
            await res400Err.initAppError();
            throw res400Err;
        }
        if (status === 401) {
            throw new Response401(res, url);
        }
        if (status === 403) {
            const res403Err = new Response403(res, url);
            await res403Err.initResponseError();
            throw res403Err;
        }
        if (status === 404 || status === 410) {
            throw new Response404(res, url);
        }
        if (status === 504) {
            throw new Response504(res, url);
        }
        if (status >= 500) {
            // if (services && services.serverRestApi && services.serverRestApi.isEmpty()) {
            //     const content = await res.text();
            //     services.serverRestApi.setResponseData({content, status, url: res.url});
            // }
            throw new Response5xx(res, url);
        }
        if (!res.ok) {
            // status out of the range 200 - 299
            throw new ResponseUnknownStatus(res, url);
        }

        return res;
    };

/* eslint-disable @typescript-eslint/no-explicit-any */

let initialized = false;
let debug = false;

/**
 * Utilities
 */

const verifyInit = () => {
    if (!initialized) {
        console.warn("Pixel not initialized before using call ReactPixel.init with required params");
    }
    return initialized;
};

//
const log = (...args: any[]) => {
    console.log(...["[react-facebook-pixel]"].concat(args));
};

//
const defaultOptions = {
    autoConfig: true,
    debug: false
};

export const RPFacebookPixelId = "811557778984749";

export const FacebookPixel = {
    init(pixelId: string, advancedMatching: Record<string, string> = {}, options: Record<string, boolean> = defaultOptions) {
        !(function (f: any, b: Document, e: "script", v: string, n?: any, t?: any, s?: any): any {
            if (f.fbq) {
                return;
            }
            n = f.fbq = function () {
                // eslint-disable-next-line prefer-spread,prefer-rest-params
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) {
                f._fbq = n;
            }
            n.push = n;
            n.loaded = true;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = true;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
        /* tslint:enable */
        initialized = true;

        if (!pixelId) {
            console.warn("Please insert pixel id for initializing");
        } else {
            if (options.autoConfig === false) {
                FacebookPixel.fbq("set", "autoConfig", false, pixelId);
            }

            FacebookPixel.fbq("init", pixelId, advancedMatching);

            debug = options.debug;
            FacebookPixel.pageView();
        }
    },

    pageView() {
        if (!verifyInit()) {
            return;
        }

        FacebookPixel.fbq("track", "PageView"); // eslint-disable-line no-undef

        if (debug) {
            log("called fbq('track', 'PageView');");
        }
    },

    track(title: string, data?: Record<string, string | number | string[] | number[] | unknown[]>) {
        if (!verifyInit()) {
            return;
        }

        FacebookPixel.fbq("track", title, data); // eslint-disable-line no-undef
        if (debug) {
            log(`called fbq("track", "${title}");`);

            if (data) {
                log("with data", data);
            }
        }
    },

    trackCustom(event: string, data: Record<string, any>) {
        if (!verifyInit()) {
            return;
        }

        FacebookPixel.fbq("trackCustom", event, data); // eslint-disable-line no-undef

        if (debug) {
            log(`called fbq("trackCustom", "${event}");`);

            if (data) {
                log("with data", data);
            }
        }
    },

    fbq(...args: any[]) {
        if (!verifyInit()) {
            return;
        }
        (window as any).fbq(...args); // eslint-disable-line no-undef

        if (debug) {
            log(`called fbq('${args.slice(0, 2).join("', '")}')`);

            if (args[2]) {
                log("with data", args[2]);
            }
        }
    }
};

import type {Agent, Span, Transaction} from "elastic-apm-node";
import {Response} from "node-fetch";

import {consoleError} from "@pg-mono/logger";
import {isEmpty} from "@pg-mono/nodash";

import {recognizeResponseErrors} from "./error/recongnize_response_error";
import {ResponseError, ResponseTimeoutError, ResponseUnknownError} from "./error/response_error";
import {IServerProfileService} from "./services/server_profile_service";
import {IServerRequestHeadersService} from "./services/server_request_headers_service";
import {IServerResponseHeadersService} from "./services/server_response_headers_service";
import {getApmTransactionName} from "./utils/get_apm_transaction_name";
import {getCookie, getCookieFromText} from "./get_cookie";
import {IFetchOptions, isomorphicFetch} from "./isomorphic_fetch";
import {matchApiUrlByApiPrefix} from "./match_api_url_by_api_prefix";
import {RequestMethod} from "./request_methods";

/**
 * TODO: design another way to pass headers to request functions,
 * ie. we can place them in the 'meta' object
 */

export enum ResponseParseType {
    TEXT,
    JSON,
    BLOB
}

const EXEC_ENV = process.env.EXEC_ENV;
if (EXEC_ENV == null) {
    const errMsg = "request package: EXEC_ENV is not defined";
    consoleError(errMsg);
    throw new Error(errMsg);
}
const isServer = EXEC_ENV === "server";

export interface IRequestOptions {
    clientApiUrl: string | null; // apiUrl that should prefix the given URL in client side
    serverApiUrl: string | null; // apiUrl that should prefix the given URL in server side
    // optional
    customServerUrlSite?: string; // allows to overwrite `serverApiUrl` url-site (specific use-case: the `url` already contains full URL)
    responseParseType?: ResponseParseType; // parse response with `res.text()`, `res.json(), `res.blob()`
    timeout?: number; // max time to wait for request, then throw ResponseTimeoutError
    serverApiUrls?: IRequestMeta["serverApiUrls"]; // object with API urls dedicated for specific API prefix path
}

export const request = {
    create:
        <TMeta extends IRequestMeta, TRes>(requestOptions: IRequestOptions) =>
        (meta: Partial<TMeta>, url: string, fetchOptions: IFetchOptions): Promise<TRes> => {
            let span: Span | null = null;

            if (isServer) {
                const pathApiUrl = matchApiUrlByApiPrefix(requestOptions.serverApiUrls, url);

                if (requestOptions.customServerUrlSite != null) {
                    url = requestOptions.customServerUrlSite + url;
                } else {
                    if (requestOptions.serverApiUrl == null && !pathApiUrl) {
                        const errMsg = "request: neither serverApiUrl nor pathApiUrl is defined for server environment";
                        consoleError(errMsg, url, fetchOptions);
                        throw new Error(errMsg);
                    }
                    url = (pathApiUrl || requestOptions.serverApiUrl) + url;
                }

                if (meta.apm) {
                    span = meta.apm.transaction.startSpan(getApmTransactionName(url), "http", "node-fetch", {childOf: meta.apm.transaction.traceparent});

                    span?.addLabels({url}, true);
                }
            } else if (process.env.EXEC_ENV === "browser") {
                if (requestOptions.clientApiUrl != null) {
                    url = requestOptions.clientApiUrl + url;
                }
            }
            const finalFetchOptions = {
                ...fetchOptions,
                headers: {
                    ...((meta.serverRequestHeaders && meta.serverRequestHeaders.getHeaders()) || {}),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    ...fetchOptions.headers,
                    ...(isServer && span ? {traceparent: span.traceparent, tracestate: meta.apm?.tracestate} : {})
                }
            };
            // fetch logic
            meta.serverProfile?.start(url);
            const mainPromise = isomorphicFetch(url, finalFetchOptions)
                .then((res: Response) => {
                    span?.end();
                    span?.setOutcome(res.ok ? "success" : "failure");

                    meta.serverProfile?.stop(url);

                    // if (requestIdService && !requestIdService.isRequestHashValid(requestId, actionRequestIdHash)) {
                    //     throw new ResponseStalledError(res, url);
                    // }

                    meta.serverResponseHeaders && meta.serverResponseHeaders.appendHeaders(res.headers);
                    return res;
                })
                .then(recognizeResponseErrors(url))
                .then((res: Response) => {
                    if (res.status === 204) {
                        return null;
                    }
                    switch (requestOptions.responseParseType) {
                        case ResponseParseType.TEXT:
                            return res.text();
                        case ResponseParseType.BLOB:
                            return res.blob();
                        case ResponseParseType.JSON:
                        default:
                            return res.json();
                    }
                })
                .catch((err: unknown) => {
                    // final error catch to name all unknown errors
                    if (err instanceof ResponseError) {
                        throw err;
                    } else {
                        throw new ResponseUnknownError(err as Error, url);
                    }
                });
            if (requestOptions.timeout != null) {
                return Promise.race([
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            span?.end();
                            span?.setOutcome("failure");

                            reject(new ResponseTimeoutError(url));
                        }, requestOptions.timeout as number);
                    }),
                    mainPromise
                ]);
            } else {
                return mainPromise;
            }
        }
};

/**
 * Export
 */
export interface IRequestMeta extends IRequestServices {
    clientApiUrl: {
        main: string | null;
    };
    serverApiUrl: {
        main: string;
        internal: string;
    };
    serverApiUrls?: Record<string, string>;
    internalApiCookieDomain: string;
}

export interface IRequestServices {
    serverProfile: IServerProfileService;
    serverRequestHeaders: IServerRequestHeadersService;
    serverResponseHeaders: IServerResponseHeadersService;
    apm?: {
        agent: Agent;
        transaction: Transaction;
        tracestate?: string;
    };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// common GET function
export const getRequest = <TMeta extends IRequestMeta, TRes = any>(
    meta: Partial<TMeta>,
    url: string,
    responseParseType?: ResponseParseType,
    headers?: Record<string, string>
): Promise<TRes> => {
    const requestOptions: IRequestOptions = {
        clientApiUrl: meta.clientApiUrl?.main ?? null,
        serverApiUrl: meta.serverApiUrl?.main ?? null,
        responseParseType: responseParseType,
        timeout: 30000,
        serverApiUrls: meta.serverApiUrls
    };
    const fetchOptions: IFetchOptions = {
        method: RequestMethod.GET,
        headers: {
            ...(process.env.API_CACHE_FORCE_MISS === "1" ? {"x-cache-force-miss": "1"} : {}),
            ...headers
        }
    };
    return request.create<TMeta, TRes>(requestOptions)(meta, url, fetchOptions);
};
// internal (optimized) GET function - initially used to fetch `api/sessions/user_info/`
export const getInternalRequest = <TMeta extends IRequestMeta, TRes = any>(meta: Partial<TMeta>, url: string): Promise<TRes> => {
    const requestOptions: IRequestOptions = {
        clientApiUrl: meta.clientApiUrl?.main ?? null,
        serverApiUrl: meta.serverApiUrl?.internal ?? null
    };
    const fetchOptions: IFetchOptions = {
        method: RequestMethod.GET
    };
    return request
        .create<TMeta, TRes>(requestOptions)(meta, url, fetchOptions)
        .then((res) => {
            const resHeaders = meta.serverResponseHeaders && meta.serverResponseHeaders.getHeaders();
            if (resHeaders) {
                // validate cookies Domain
                meta.internalApiCookieDomain && meta.serverResponseHeaders && meta.serverResponseHeaders.validateCookieDomain(meta.internalApiCookieDomain);
            }
            return res;
        });
};

export const getExternalRequest = <TMeta extends IRequestMeta, TRes = any>(meta: Partial<TMeta>, url: string, timeout: number): Promise<TRes> => {
    const requestOptions: IRequestOptions = {
        clientApiUrl: meta.clientApiUrl?.main ?? null,
        serverApiUrl: meta.serverApiUrl?.main ?? null,
        timeout,
        customServerUrlSite: ""
    };
    const fetchOptions: IFetchOptions = {
        method: RequestMethod.GET,
        credentials: "cross-origin"
    };
    return request.create<TMeta, TRes>(requestOptions)(meta, url, fetchOptions);
};

// common POST function
export const postRequest = <TMeta extends IRequestMeta, TRes = any>(
    meta: Partial<TMeta>,
    url: string,
    data: Record<string, unknown> | unknown[],
    responseParseType?: ResponseParseType,
    headers?: Record<string, string>
): Promise<TRes> => {
    const requestOptions: IRequestOptions = {
        clientApiUrl: meta.clientApiUrl?.main ?? null,
        serverApiUrl: meta.serverApiUrl?.main ?? null,
        responseParseType: responseParseType
    };
    const fetchOptions: IFetchOptions = {
        method: RequestMethod.POST
    };
    if (data) {
        fetchOptions.body = JSON.stringify(data);
    }
    // provide csrf token as special header alongside cookie value
    if (process.env.EXEC_ENV === "browser") {
        const csrf = getCookie("csrftoken");
        if (csrf) {
            fetchOptions.headers = {
                "X-CSRFToken": csrf
            };
        }
    } else if (process.env.EXEC_ENV === "server") {
        const serverRequestHeaders = meta.serverRequestHeaders?.getHeaders();
        if (serverRequestHeaders && serverRequestHeaders.cookie) {
            const csrf = getCookieFromText("csrftoken", serverRequestHeaders.cookie as string);
            if (csrf) {
                fetchOptions.headers = {
                    "X-CSRFToken": csrf
                };
            }
        }
    }
    if (!isEmpty(headers)) {
        fetchOptions.headers = {
            ...(fetchOptions.headers ? fetchOptions.headers : {}),
            ...(headers ? headers : {})
        };
    }
    return request.create<TMeta, TRes>(requestOptions)(meta, url, fetchOptions);
};

// common POST function for external API
export const postExternalRequest = <TMeta extends IRequestMeta, TRes = any>(
    meta: Partial<TMeta>,
    url: string,
    data: Record<string, unknown> | string,
    headers?: Record<string, string>
): Promise<TRes> => {
    const requestOptions: IRequestOptions = {
        clientApiUrl: meta.clientApiUrl?.main ?? null,
        serverApiUrl: meta.serverApiUrl?.main ?? null,
        customServerUrlSite: ""
    };

    const fetchOptions: IFetchOptions = {
        method: RequestMethod.POST,
        credentials: "same-origin",
        headers: headers || {}
    };

    if (data) {
        fetchOptions.body = typeof data === "string" ? data : JSON.stringify(data);
    }

    return request.create<TMeta, TRes>(requestOptions)(meta, url, fetchOptions);
};

// common PATCH function
export const patchRequest = <TMeta extends IRequestMeta, TRes = any>(meta: Partial<TMeta>, url: string, data: Record<string, unknown>): Promise<TRes> => {
    const requestOptions: IRequestOptions = {
        clientApiUrl: meta.clientApiUrl?.main ?? null,
        serverApiUrl: meta.serverApiUrl?.main ?? null
    };
    const fetchOptions: IFetchOptions = {
        method: RequestMethod.PATCH
    };

    if (data) {
        fetchOptions.body = JSON.stringify(data);
    }
    if (process.env.EXEC_ENV === "browser") {
        const csrf = getCookie("csrftoken");
        if (csrf) {
            fetchOptions.headers = {
                "X-CSRFToken": csrf
            };
        }
    }
    return request.create<TMeta, TRes>(requestOptions)(meta, url, fetchOptions);
};

// common DELETE function
export const deleteRequest = <TMeta extends IRequestMeta, TRes = any>(meta: Partial<TMeta>, url: string, data: Record<string, unknown>): Promise<TRes> => {
    const requestOptions: IRequestOptions = {
        clientApiUrl: meta.clientApiUrl?.main ?? null,
        serverApiUrl: meta.serverApiUrl?.main ?? null
    };
    const fetchOptions: IFetchOptions = {
        method: RequestMethod.DELETE
    };

    if (data) {
        fetchOptions.body = JSON.stringify(data);
    }
    if (process.env.EXEC_ENV === "browser") {
        const csrf = getCookie("csrftoken");
        if (csrf) {
            fetchOptions.headers = {
                "X-CSRFToken": csrf
            };
        }
    }
    // TODO: update "headers["X-CSRFToken"]"
    return request.create<TMeta, TRes>(requestOptions)(meta, url, fetchOptions);
};
